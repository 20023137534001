.footer__container {
    width: 100%;
    background: #222
}

.contact__flex {
  width: 95vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 0px;
  color: #f8f8f8;
}

.contact__flex div {
  width: min(300px, 90vw);
}
.contact__flex h3 {
  margin-bottom: 18px;
}

.contact__flex .contact__center a {
  font-size: 1rem;
  font-weight: 500;
  filter: brightness(1.2);
}
.contact__flex .contact__center .contact__email {
  margin-top: 15px;
}
.contact__flex .contact__center a:hover {
  color: var(--aqua);
}
.contact__flex .contact__right {
  width: 300px;
  text-align: center;
  font-size: 1.3rem;
}
.contact__right .contact__social-flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  width: 200px;
  margin: 0 auto;

}
.contact__flex  .contact__right a {
  display: inline;
  font-size: 2rem;
  /* align-self: center; */
}
.contact__flex .contact__right .contact__facebook,
.contact__flex .contact__right .contact__x,
.contact__flex .contact__right .contact__instagram,
.contact__flex .contact__right .contact__youtube {
  padding: 4px 18px 0px;
  margin: 0 5px;
  width: max-content;
  align-self: center;
}

.contact__flex .contact__facebook:hover,
.contact__flex .contact__x:hover,
.contact__flex .contact__instagram:hover,
.contact__flex .contact__youtube:hover {
  color: var(--aqua);
  cursor: pointer;
}



.footer__container .footer__belt {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  padding: 50px 0 10px;
}
.footer__container .footer__belt img {
  border: none;
  box-shadow: none;
  height: 40px;
  width: 40px;
}




/* .footer__container .footer__belt .footer__belt-md {
  height: 90px;
  width: 90px;
}
.footer__container .footer__belt .footer__belt-sm {
  height: 80px;
  width: 80px;
} */

.footer__container .attribution p,
.footer__container .attribution a { 
  font-size: 11px; 
  text-align: center;
  color: #f8f8f8; 
}
.attribution a:hover { color: var(--aqua); }

@media screen and (max-width: 980px) {
  .contact__flex {
    flex-direction: column;
    height: 450px;
    padding: 20px 0px 0px;
  }
  .contact__flex div {
    margin: auto;
    text-align: center;
  }
  .contact__flex h3 {
    margin-bottom: 10px;
    margin-top: 45px;
    font-weight: 600;
  }
  .contact__flex .contact__left h3 {
    margin-top: 10px;
  }
  .contact__flex p {
    margin-bottom: 2px;
  }
  .contact__flex .contact__right {
    font-size: 1.3rem;
  }
  .footer__container .footer__belt {
    margin: 50px 0 0px;
  }
}


/* @media screen and (max-width: 700px){
  .footer__container .footer__belt img {
      height: 70px;
      width: 70px;
  }
  .footer__container .footer__belt .footer__belt-md {
    height: 65px;
    width: 65px;
  }
  .footer__container .footer__belt .footer__belt-sm {
      height: 60px;
      width: 60px;
  }
} */


@media screen and (max-width: 450px) {
  .footer__container .footer__belt {
    width: 95vw;
    margin: 50px auto 0px;
    padding: 50px 0 0px;
    justify-content: space-between;
  }
  /* .footer__container .footer__belt img {
    height: 40px;
    width: 40px;
  }
  .footer__container .footer__belt .footer__belt-md {
    height: 36px;
    width: 36px;
  }
  .footer__container .footer__belt .footer__belt-sm {
      height: 32px;
      width: 32px;
  } */
  .contact__flex  .contact__right {
    width: 90vw;
  }

}