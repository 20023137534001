.donations__container {
    padding-bottom: 100px;
}

.donations__grid {
    display: grid;
    margin: 40px auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 30px;
}
.donations__grid h2 {
    text-align: center;
    font-weight: 400;
    font-size: 3rem;
}
.donations__grid button {
    display: block;
    margin: 40px auto;
}

.donations__content p {
    width: min(50ch, 92%);
    margin: 18px auto;
    text-align: justify;
}
.donations__content p a {
    font-size: inherit;
    font-weight: 600;
}
.donations__content p a:hover {
    color: var(--blue);
}
.donations__content .donations__taxes {
    font-weight: 600;
}

.donations__grid img {
    width: min(500px, 92vw);
    height: min(500px,92vw);
    object-fit: cover;
    object-position: 65%;
    margin: 60px auto 0;
    align-self: flex-start;
}

@media screen and (max-width: 1100px) {
    .donations__grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        padding: 30px 2px;
    }
    .donations__grid img {
        margin: auto;
    }
}
@media screen and (max-width: 510px) {
    .donations__grid h2 {
        text-align: left;
        margin-left: 4vw;
    }
    .donations__grid button {
        margin-left: 4vw;
    }
    
}