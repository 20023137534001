.landing__hero {
    position: relative;
    width: 100%;
    height: min(100vh, 800px);
    min-height: 600px;
    overflow: hidden;
    margin-bottom: 80px;
}
  
.landing__hero video,
.landing__hero img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landing__container .landing__hero-content {
    position: absolute;
    bottom: 100px;
    /* right: 0px;
    border-radius: 10px 0 0 10px; */
    padding: 20px;
    width: min(350px, 80vw);
    background: rgba(0, 0, 0, 0.557);
    left: 0px;
    border-radius: 0 10px 10px 0;
}

.landing__hero-content h1 {
    text-align: center;
    font-size: 5rem;
    font-weight: 400;
    color: #f8f8f8;
    margin-bottom: 30px;
}
.landing__hero-content .landing__logo {
    display: block;
    display: none;
    height: 200px;
    width: 200px;
    margin:  0px auto 0px;
    box-shadow: none;
    border: none;
    padding: 20px;
    position: relative;
    z-index: 2;
}
.landing__hero-content .landing__logo::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.landing__hero-content h4 {
    color: #f8f8f8;
    font-size: 1.5rem;
    text-align: center;
}

.landing__container .aztec-banner {
    margin-top: 0px;
    margin-bottom: 0px;
    width: min(1200px, 100%);
    margin: auto;
    height: 20px;
    background-image: url('../../img/border-top.png');
    background-size: auto 20px;
    background-repeat: repeat;
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .landing__container .aztec-banner:nth-child(4) {
    rotate: 180deg;
  }



.landing__container h2 {
    margin: 80px 0 50px;
    text-align: center;
    font-weight: 400;
    font-size: 3rem;
}
.landing__container h2 span {
    color: #f8f8f8;
    font-weight: 500;
    font-family: 'CulturaMedium';
}
.landing__cta-container {
    background: var(--blue);
    padding: 0px 0px 0px;
    width: min(1200px, 100%);
    margin: 50px auto;
}
.landing__cta-container .landing-donate__title p {
    color: #f8f8f8;
    font-size: 3rem;
    font-weight: 300;
}
.landing__cta-container .landing-donate__title p strong {
    font-weight: 500;
    font-family: 'CulturaMedium';
}
.landing__cta {
    display: block;
    margin: 50px auto 100px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: min(1000px, 92vw);
    color: #f8f8f8;
    gap: 25px;
}
.landing__cta h2,
.landing__container .landing__cta p {
    font-weight: 400;
    color: #f8f8f8;
    width: min(50ch, 100%);
    padding: 0;
}
.landing__cta-button {
    height: max-content;
    border-width: 3px;
    font-weight: 600;
    border-radius: 0px;
    background: transparent;
    white-space: nowrap;
    color: #f8f8f8;
    border-color: #f8f8f8;
}
.landing__cta-button:hover {
    background: linear-gradient(to bottom, #89E0B6, hsl(151, 48%, 61%));
    transition: 2s;
    color: #222;
    border-color: #222;
}
.landing__container p {
    width: min(50ch, 92%);
    padding-right: 20px;
    font-size: 1.3rem;
}

.landing__mailing-list {
    margin: 80px auto 0px;
    padding: 0 0 100px;
    background: url(../../img/tapestry.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.landing__mailing-list-flexbox {
    display: flex;
    width: 1000px;
    background:  #00f1d162;
    backdrop-filter: blur(5px);

    box-shadow: var(--sharp);
    padding: 30px;
    justify-content: center;
    align-items: center;
    margin: 35px auto 0;
}
.landing__container .landing__mailing-list h2 {
    text-align: center;
    margin: 0 0 20px;
    padding: 20px 0px;
    color: #f8f8f8;
    background: #222222e5;
    backdrop-filter: blur(4px);
    box-shadow: var(--sharp);
}
.landing__mailing-list input {
    display: block;
    margin: 20px auto;
    height: 40px;
    width: min(250px, 95%);
    padding-left: 8px;
    font-size: 1.4rem;
    border: 1px solid var(--text);
}
.landing__mailing-list input:focus {
    outline-color: var(--blue);
}
.landing__mailing-list button {
    display: block;
    margin: 20px auto;
    height: 42px;
    padding: 0 5px;
}
.landing__mailing-list .error {
    border: 3px solid var(--red);
    background: #f8f8f8;
    color: var(--text);
}


@media screen and (max-width: 1075px) {
    .landing__mailing-list-flexbox {
        display: flex;
        flex-direction: column;
        width: min(500px, 90vw);
        padding: 20px 8px 5px;
        justify-content: center;
        align-items: center;
    }
    .landing__mailing-list button {
        padding: 0px 20px;
    }
}

@media screen and (max-width: 755px) {
    .landing__cta {
        flex-direction: column;
        gap: 50px;
        padding: 0;
    }
    .landing__cta-container h2 {
        padding: 12px;
        margin: 20px auto 0;
    }
    .landing__cta-container button {
        margin: 20px auto -60px;
    }
}

@media screen and (max-width: 450px) {
    .landing__container h1 {
        font-size: 3.2rem;
    }
}