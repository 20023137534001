.sponsors__container {
    padding-bottom: 120px;
}
.sponsors__container .sponsor__paragraph {
    width: min(50ch, 95vw);
    margin: 15px auto 40px;
    font-size: 1.2rem;
    padding: 0 5px;
}
.sponsors__banner h2 {
    display: block;
    width: max-content;
    margin: 50px auto;
    color: var(--text);
    padding: 10px 20px;
    font-size: 3.3rem;
}
.sponsors__triangle-border {
    margin-top: 1px;
    margin-bottom: 0px;
    width: min(1200px, 100vw);
    height: 40px;
    background-image: url('../../img/triangle-border (2).svg');
    background-size: auto 40px;
    background-repeat: repeat;
    border: none;
  }
.sponsors__triangle-border-bottom {
    margin-bottom: .2px;
    width: min(1200px, 100vw);
    height: 40px;
    background-image: url('../../img/triangle-border (2).svg');
    background-size: auto 40px;
    background-repeat: repeat;
    border: none;
    rotate: 180deg;
  }
.sponsors__new-idea {
    background: var(--text);
    color: #f8f8f8;
    padding: .2px 0px;
    width: min(1200px, 100vw);
    margin: 0 auto 50px;
}


.sponsors__flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px auto;
}

.sponsors__flex-item {
    flex-grow: 1;
    width: 300px;
    padding: 30px;
    display: block;
    margin: 0px auto;
    text-align: center;
}


.sponsors__icon {
    height: 100px;
    width: 100px;
    display: block;
    margin: 5px auto 30px;
    border: none;
    box-shadow: none;
}
.sponsors__flex .sponsors__flex-item p {
    font-size: 1.3rem;
    color: #f8f8f8;
    margin: 30px auto 4px;
    width: 92%;
}

.sponsors__container button {
    display: block;
    margin: 80px auto 0;
}

.sponsors__images h2 {
    margin: 150px 0 50px;
    text-align: center;
}


.sponsors__images img {
    border: 2px solid #333;
    box-shadow: var(--spread);
    margin: auto;
}
.sponsors__images .sponsors__sponsors-wide {
    display: block;
    width: 100%;
}
.sponsors__images .sponsors__sponsors-normal {
    display: none;
}
.sponsors__images .sponsors__sponsors-thin {
    display: none;
}


@media screen and (max-width: 1200px) {
    .sponsors__flex {
        display: grid;
        grid-template-columns: min-content min-content;
        margin: 20px 0;
        grid-gap: none;
    }
    .sponsors__flex-item {
        width: 47.5vw;
    }
}
@media screen and (max-width: 660px) {
    .sponsors__container {
        padding-bottom: 50px;
    }
    .sponsors__container .sponsor__paragraph {
        font-size: 1.1rem;
    }
    .sponsors__flex {
        display: grid;
        grid-template-columns: 1fr;
        margin: 10px auto;
        grid-gap: none;
        width: 100%;
    }
    .sponsors__flex-item {
        width: 100%;
    }
    .sponsors__banner h2 {
        font-size: 2rem;
        /* font-family: var(--noble); */
    }
}


@media screen and (max-width: 1200px) {
    .sponsors__images .sponsors__sponsors-wide {
        display: none;
    }
    .sponsors__images .sponsors__sponsors-normal {
      display: block;
      width: min(800px, 95%);
    }
    .sponsors__images .sponsors__sponsors-thin {
        display: none;
      }

  }

@media screen and (max-width: 640px) {
     .sponsors__images .sponsors__sponsors-wide {
        display: none;
    }
    .sponsors__images .sponsors__sponsors-normal {
        display: none;
    }
    .sponsors__images .sponsors__sponsors-thin {
        display: block;
        width: min(400px, 95%);
    }
}
