@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'CulturaBold';
  src: url('./fonts/Blur-Bold.ttf') format('truetype');
  /* Add additional src lines for different font file formats if available */
}
@font-face {
  font-family: 'CulturaMedium';
  src: url('./fonts/Blur-Medium.ttf') format('truetype');
  /* Add additional src lines for different font file formats if available */
}
@font-face {
  font-family: 'CulturaLight';
  src: url('./fonts/Blur-Light.ttf') format('truetype');
  /* Add additional src lines for different font file formats if available */
}

/* CSS Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Layout */

:root {
  /* Colors */
  --red: #B75528;
  --pink: #E89A84;
  --green: #989A2C;
  --blue: #2C7268;
  /* --aqua: #89E0B6; */
  --aqua: #00F1CF;
  --brown: #64502F;
  --text: #313C2E;
  --feature: #D9D3C1;
/* 
  --red: hsl(19, 84%, 64%);
  --pink: hsl(13, 88%, 81%);
  --green: hsl(61, 76%, 49%);
  --blue: hsl(171, 64%, 41%);
  --aqua: hsl(151, 78%, 71%);
  --brown: hsl(37, 56%, 39%);
  --text: hsl(107, 33%, 61%);
  --feature: hsl(45, 44%, 80%); */


  /* Border Radius */
  --radius-sm: 6px;
  --radius-lg: 24px;

  /* Fonts */
  --elegant: 'MonteCarlo', cursive;
  --noble: 'Cormorant Garamond', serif;
  --clear: 'Poppins', sans-serif;
  --fun: 'Montserrat Alternates', sans-serif;
  --normal: "GeneralSans", Arial, sans-serif, serif;

  /* Shadow */
  --spread: 4px 4px 40px rgba(46, 46, 47, 0.494);
  --sharp: 5px 5px 8px 2px rgba(0, 0, 0, 0.4);

}

body {
  font-family: var(--clear);
  font-weight: 400;
  /* background: linear-gradient(#e4f3f3, #e1f6f6); */
  background-color: #f8f8f8;
  min-height: 100vh;
  width: 100%;
  color: var(--text);
}

h1,
h2,
h3,
h4,
label {
  /* font-family: 'Cormorant Garamond', serif; */
  font-weight: 600;
  font-family: 'CulturaLight', serif;
}

h1 {
  text-align: center;
  font-size: 2.4rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 1.6rem;
}
h4,
a {
  font-size: 1.3rem;
}

button {
  height: max-content;
  border: 3px solid var(--text);
  color: var(--text);
  font-weight: 600;
  padding: 10px 20px;
  font-size: 26px;
  background: #f8f8f8;
  white-space: nowrap;
  transition: 1s;
  font-family: var(--clear);
}
button:hover {
  background: linear-gradient(to bottom, #89E0B6, hsl(151, 48%, 61%));
}


img {
  box-shadow: var(--sharp);
  /* border: 5px solid #f8f8f8; */
}
a {
  text-decoration: none;
  color: inherit;
}

p a {
  font-weight: 600;
  font-size: inherit;
  text-decoration: underline dotted 2px #555;
}

p a:hover {
  text-decoration: underline dotted 2px var(--blue); 
  color: var(--blue);
}

a:hover,
button:hover {
  filter: brightness(1.3);
  cursor: pointer;
}

.error {
  border: 1px solid var(--red);
  color: var(--red);
  padding: 5px 10px;
  margin: 20px auto;
  max-width: 90%;
  background-color: var(--pink);
}

@media screen and (max-width: 380px) {
  button {
    font-size: 1rem;
  }
}