.upcomingEvents__title {
    color: #222;
    text-align: center;
    /* font-family: var(--noble); */
    font-size: 3rem;
    font-weight: 600;
}

.upcomingEvents__flexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#noUpcomingEvents {
    color: var(--red);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
    padding: 10px;
    background: var(--pink);
    border: 2px solid var(--red);
    box-shadow: var(--spread);
}