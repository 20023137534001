.navbar__container {
    background: #222;
    height: 389px;
    width: 100%;
    padding: 8px;
}

.navbar__desktop {
    display: block;
}
.navbar__container .navbar__language-toggle {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    padding: 10px 20px;
    background-image: linear-gradient(120deg, #00FFB3, #00FFF0, #00FFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid;
    border-image: linear-gradient(120deg, #00FFB3, #00FFF0, #00FFFF) 1;
}

.navbar__container .navbar__language-toggle:hover {
    cursor: pointer;
    filter: brightness(1.2);
    border: 4px solid;
    border-image: linear-gradient(120deg, #00FFB3, #00FFF0, #00FFFF) 1;
}

.navbar__logo-container {
    position: relative;
    height: 250px;
    width: 260px;
    box-shadow: none;
    margin: 0px auto;
    padding: 0;
    /* border: 1px solid white; */
}
.navbar__logo-container:hover img,
.navbar__logo-container:hover .navbar__logo-h1
{
  cursor: pointer;
  /* filter:hue-rotate(20deg); */
  filter: brightness(1.8);
  border-radius: 12px;
} 
.navbar__logo-container img {
    height: 236px;
    width: 256px;
    object-fit: cover;
    box-shadow: none;
    display: block;
    margin: 0 auto;
    padding: 0;
    filter:opacity(1);
    animation: opacityAnimation 30s infinite;
}
.navbar__logo-container .navbar__logo-h1 {
    font-family: 'CulturaLight';
    position: absolute;
    z-index: 0;
    bottom: 9px;
    right: 50%;
    transform: translateX(50%);
    font-size: 2.5rem;
    background-image: linear-gradient(177deg, #e6fff7, #84fde5, #00FFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: opacityAnimation 30s infinite;
    white-space: nowrap;
}

@keyframes opacityAnimation {
    0% {
        opacity: 1.2;
    }
    20% {
        opacity: 1.2;
    }
    39% {
        opacity: .85;
    }
    50% {
        opacity: 1;
    }
    63% {
        opacity: .75;
    }
    93% {
        opacity: 1.2;
    }
    100% {
        opacity: 1.2;
    }
}

.navbar__main-item img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -40px;
    right: 50%;
    transform: translateX(50%);
    box-shadow: none;
}
.navbar__main-item:hover img,
.navbar__main-item:hover p,
.navbar__main-item:hover .navbar__arrow {
    filter: brightness(1.5);
}
#communityLg:hover img,
#communityLg:hover p,
#communityLg:hover .navbar__arow
 {
    filter: brightness(1.2);
}

.navbar__main-menu .selected {
    /* border-bottom: 3px solid #f8f8f8; */
    margin-bottom: -3px
}
.navbar__main-menu .selected-about {
    border-bottom: 3px solid hsl(107, 100%, 69%);
    margin-bottom: -3px;
}
.navbar__main-menu .selected-season {
    border-bottom: 3px solid hsl(61, 100%, 53%);
    margin-bottom: -3px;
}
.navbar__main-menu .selected-involved {
    border-bottom: 3px solid hsl(359, 79%, 61%);
    margin-bottom: -3px;
}
.navbar__main-menu .selected-community {
    border-bottom: 3px solid #FFA209;
    margin-bottom: -3px;
}
.navbar__main-menu .selected-contact {
    border-bottom: 3px solid hsl(318, 96%, 70%);
    margin-bottom: -3px;
}
.navbar__main-menu {
    display: flex;
    position: relative;
    justify-content: space-around;
    gap: 2rem;
    list-style-type: none;
    margin-top: 70px;
}
.navbar__container .navbar__main-item .color-about {
    color: hsl(107, 100%, 69%);
}
.navbar__container .navbar__main-item .color-season {
    color: hsl(61, 100%, 53%);
}
.navbar__container .navbar__main-item .color-involved {
    color: hsl(359, 79%, 61%);
}
.navbar__container .navbar__main-item .color-community {
    color: #FFA209;
}
.navbar__container .navbar__main-item .color-contact {
    color: hsl(318, 96%, 70%);
}

#border-about  {
    border: 2px solid #209600;
}
#border-about:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #209600;
}
#border-season  {
    border: 2px solid #D5D900;
}
#border-season:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #D5D900;
}
#border-involved  {
    border: 2px solid hsl(359, 79%, 61%);
}
#border-involved:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #E73437;
}
#border-community  {
    border: 2px solid #FFA209;
}
#border-community:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #FFA209;
}
#border-contact  {
    border: 2px solid #FA17B6;
}
#border-contact:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #FA17B6;
}

.navbar__icon {
    display: none;
}

.navbar__main-item {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbar__main-item p {
    display: block;
    padding: 10px;
    text-decoration: none;
    /* font-family: var(--noble); */
    font-family: 'CulturaLight';
    font-size: 1.4rem;
    font-weight: 900;
    /* color: #FA17B6;
    color: #00F1CF;
    color: #f8f8f8; */
    filter: brightness(.8);
}

.navbar__arrow {
    transform: translateY(2px);
    /* color: #FA17B6;
    color: #00F1CF;
    color: #f8f8f8; */
}

.navbar__main-menu .sub-menu {
    position: absolute;
    top: 61px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    background: #333;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 0px;
    /* border: 1px solid #f8f8f8; */
    box-shadow: var(--sharp);
}
.sub-menu .navbar__sub-item {
    width: 100%;
    display: block;
    color: #f8f8f8;
    /* padding: 10px; */
    text-decoration: none;
    /* font-family: var(--noble); */
    font-family: 'CulturaLight';
    font-weight: 300;
    font-size: 1.2rem;
    /* color: #FA17B6;
    color: #00F1CF;
    color: #f8f8f8; */
    filter: brightness(.8);
}
.sub-menu .navbar__sub-item a {
    display: block;
    width: 100%;
    background: #333;
    transition: 400ms;
    padding: 10px;
}
.sub-menu .navbar__sub-item a:hover {
    background: #3d3d3d;
}
.navbar__container .navbar__sub-item .navbar__social-icon {
    margin-bottom: -4.24px;
}
.navbar__container .sub-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid #f8f8f8;
}



/* ------------MEDIA QUERIES--------------*/


@media screen and (max-width: 1220px) {
    .navbar__container .navbar__main-item .sub-menu-left {
        left: 80%;
    }
}
@media screen and (max-width: 1020px) {
    .navbar__container .navbar__main-item .sub-menu-right {
        left: 40%;
    }
}




@media screen and (max-width:910px) {
    .navbar__container {
        position: relative;
        z-index: 100;
        height: 280px;
        background: #222;
        width: 100vw;
    }

    .navbar__desktop {
        display: none;
    }
    .navbar__phone {
        display: block;
        position: absolute;
        top: 200px;
    }

    .navbar__container .navbar__language-toggle {
        top: 20px;
        right: 20px;
        z-index: 100;
    }
    .navbar__logo-container {
        margin-top: 0px;
    }
    .navbar__container .navbar__icon {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        color: var(--aqua);
        height: 44px;
        width: 44px;
        text-align: center;
        z-index: 100;
        padding: 2px;
        border: 2px solid;
        border-image: linear-gradient(177deg, #e6fff7, #84fde5, #00FFFF) 1;
    }
    .navbar__container .navbar__icon:hover {
        cursor: pointer;
        filter: brightness(1.2);
        border: 4px solid;
        border-image: linear-gradient(177deg, #e6fff7, #84fde5, #00FFFF) 1;
    }

    .navbar__main-menu {
        position: absolute;
        background: #222;
        color: var(--text);
        transition: 1s;
        width: 80vw;
        height: max-content;
        top: -500px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1px;
        padding: 40px;
        margin-top: 0;
    }

    .active {
        top: 100%;
        transition: 1s;
        border-top: 2px solid #00F1CF;
        border-top: 2px solid #f8f8f8;
    }

    .navbar__main-item img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: -60px;
        transform: translateY(-50%);
        box-shadow: none;
    }

    .navbar__container .navbar__main-item {
        position: relative;
        transition: .3s;
        display: block;
        width: 90%;
        transition: 1s;
        background: #333;
        z-index: -9;
    }
    .navbar__main-item p {
        font-weight: 800;
        font-size: 1.6rem;
    }
    .navbar__container .navbar__arrow {
        position: absolute;
        right: 20px;
        top: 17px;
    }
    .navbar__container .navbar__main-item:hover {
        background: #434343;
    }

    .navbar__container .sub-menu {
        position: relative;
        left: 0px;
        top: 0;
        transform: translateX(0);
        width: 100%;
        height: max-content;
        background: #444;
        color: #f8f8f8;
        display: flex;
        flex-direction: column;
        z-index: 100;
        padding: 0;
        box-shadow: none;
        border: 1px solid #f8f8f8;
    }
    .sub-menu::before {
        display: none;
    }
    .navbar__container .navbar__main-item .sub-menu-right {
        left: 0;
    }
    .navbar__container .navbar__main-item .sub-menu-left {
        left: 0;
    }
    .sub-menu .navbar__sub-item {
        margin: 0;
        text-align: left;
        background: #444;
    }
    .sub-menu .navbar__sub-item  {
        text-decoration: none;
    }
}


@media screen and (max-width: 580px) {
    /* .navbar__logo-container {
        height: 200px;
        width: 200px;
        margin-top: 10px;
    }
    .navbar__logo-container img {
        height: 196px;
        width: 196px;
    } */
    /* .navbar__logo-container {
        margin-left: 0px;
    } */
    .navbar__logo-container .navbar__logo-h1 {
        font-size: 2rem;
    }

    .navbar__container .navbar__main-menu {
        padding: 10px 50px 10px 50px;
    }
    .navbar__container .navbar__icon,
    .navbar__container .navbar__logo {
      font-size: 1.4rem;
    }
    .navbar__container .navbar__main-item,
    .navbar__container .navbar__sub-item {
        font-size: 1.4rem;
        font-weight: 400;
    }
    .navbar__container .navbar__language-toggle {
        top: 20px;
        right: 20px;
        padding: 5px;
    }
    /* .navbar__logo-container .navbar__logo-h1 {
        font-size: 1.2rem;
    } */
  }

  @media screen and (max-width: 444px) {
    .navbar__container {
        height: 259px;
    }
    .navbar__logo-container {
        height: 240px;
        width: 240px;
        margin: 5px auto;
    }
    .navbar__logo-container img {
        height: 236px;
        width: 236px;
    }
    .navbar__logo-container .navbar__logo-h1 {
        font-size: 1.4rem;
        bottom: 20px;
    }
    .navbar__container .navbar__icon {
        top: 184px;
        right: 10px;
    }
    .navbar__container .navbar__language-toggle {
        top: 3px;
        right: 50%;
        transform: translateX(50%);
    }
    .navbar__container .navbar__main-menu {
        padding: 10px 10px 10px 50px;
        width: 100vw;
    }
}

