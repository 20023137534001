.eventAd__container {
    width: min(500px, 92%);
    margin: 50px 15px;
    background: var(--pink);
    padding-bottom: 20px;
    box-shadow: var(--spread);
}

.eventAd__container img {
    width: min(500px, 100%);
    height: 500px;
    object-fit: cover;
    margin: 0;
    box-shadow: none;
}
.eventAd__container h2,
.eventAd__container p {
    color: #f8f8f8;
    margin: 5px 0;
    padding: 0 8px;
    font-size: 2rem;
    width: min(500px, 100%);
    text-align: center;
}
.eventAd__container h2 {
    color: var(--red);
    /* color: #E73437; */
    font-family: 'CulturaMedium';
}
.eventAd__container p {
    font-size: 1.2rem;
}
.eventAd__container button {
    margin: 20px auto 0px;
    display: block;
}