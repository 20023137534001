.loading__container {
    position: absolute;
    top: 750px;
    left: 50vw;
    transform: translate(-50%, -50%);

    width: min(370px, 80vw);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid #f8f8f8;
    background: var(--red);
    color: #f8f8f8;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    z-index: 10000;
}

.loading__container p {
    margin: 30px 7px;
    text-align: center;
}

.loading__container button {
    display: block;
    margin: 30px auto 20px;
}

.loading__close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 2.4rem;
    border: 2px solid #f8f8f8;
}
.loading__close:hover {
    background-color: var(--pink);
    cursor: pointer;
}

.loading__check-box {
    display: block;
    margin: 10px auto;
    font-size: 2rem;
}