.eventDetails__container {
    width: min(1650px, 96%);
    margin: 40px auto 80px;
    padding: 22px 20px 40px;
    color: var(--text);
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.eventDetails__container .eventDetails__container-right {
    width: 50vw;
}
.eventDetails__container h1 {
    color: var(--blue);
    margin: 0px auto 20px;
    font-size: 4rem;
    width: 92%;
    white-space: wrap;
}
.eventDetails__container img {
    width: min(500px, 92%);
    height: min(500px, 92vw);
    display: block;
    margin: 0px auto 30px;
    object-fit: cover;
}
/* .eventDetails__container .eventDetails__poster {
    height: 500px;
    width: min(100%, 92vw);
    object-fit: contain;
} */
.eventDetails__container h3 {
    color: var(--text);
    font-size: 2.4rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 8px;
}
.eventDetails__container .eventDetails__subtitle,
.eventDetails__container .eventDetails__dates {
    text-align: center;
    padding: 0;
    margin: 0;
    width: inherit;
}
.eventDetails__container .eventDetails__dates {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 12px;
}
.eventDetails__container .eventDetails__location {
    margin: 20px auto 0;
    background-color: var(--blue);
    padding: 20px;
    width: min(500px, 92%);
    border: 1px solid #111;
    border-bottom: none;
}
.eventDetails__container .eventDetails__location .map-icon {
    font-size: 1.7rem;
    margin-bottom: -5px;
    margin-left: 10px;
}

.eventDetails__container .eventDetails__location p {
    /* text-align: center; */
    padding: 0;
    margin: 0 auto;
    color: #f8f8f8;
    white-space: nowrap;
    width: 100%;
}
.eventDetails__container .eventDetails__location a {
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: #f8f8f8;
    margin: 0 auto;
    padding: 0;
    white-space: nowrap;
    text-align: left;
    display: block;
    width: 100%;
}
.eventDetails__container .eventDetails__location-map {
    margin: 0 auto;
    box-shadow: none;
    border: 1px solid #111;
    border-top: none;
}
.eventDetails__container .eventDetails__location a:hover {
    color: var(--aqua);
    cursor: pointer;
    background: none;
    border: none;
    transition: .3s;
}

.eventDetails__container p {
    width: min(55ch, 92%);
    font-size: 1.2rem;
    /* letter-spacing: .05em; */
    margin: 30px auto;
    text-align: justify;
}

.eventDetails__container .event-youtube-video {
    width: min(500px, 90vw);
    display: block;
    margin: auto;
    box-shadow: var(--sharp);
}

.eventDetails__container .event-button {
    height: max-content;
    border: 3px solid var(--text);
    border-width: 3px;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 8px 20px;
    border-radius: 0px;
    background: transparent;
    white-space: nowrap;
    display: block;
    width: max-content;
    margin: 30px auto 35px;

}

.eventDetails__container .event-button:hover {
    background: linear-gradient(to bottom, #89E0B6, hsl(151, 48%, 61%));
    transition: 2s;
}
.eventDetails__container .eventDetails__no-tickets {
    margin: 20px auto;
    text-align: center;
}

.eventDetails__container video {
    margin: 54px auto 20px;
    display: block;
    width: min(500px, 92%);
    height: 350px;
}
.eventDetails__container h2 {
    margin: 20px auto;
    text-align: center;
}


@media screen and (max-width: 1100px) {
    .eventDetails__container {
        grid-template-columns: 1fr;
        gap: 0px;
    }
    .eventDetails__container div:first-child {
        order: 2;
    }
    .eventDetails__container div:nth-child(2) {
        order: 1;
    }
    .eventDetails__container .eventDetails__container-right {
        width: 92vw;
    }
    .eventDetails__container h2 {
        margin: 70px auto 20px;
    }
    /* .eventDetails__container iframe {
        margin: 30px auto;
        display: block;
    } */
    .eventDetails__container video {
        margin: 4px auto 20px;
    }
}
@media screen and (max-width: 1000px) {
    .eventDetails__container p {
        margin: 50px auto;
        padding-left: 0px;
    }

}
@media screen and (max-width: 580px) {
    .eventDetails__container {
        padding: 22px 9px;
        /* gap: 200px; */
    }
    .eventDetails__container h1 {
        font-size: 2rem;
    }
    .eventDetails__container h3 {
        font-size: 1.8rem;
    }
    .eventDetails__container .eventDetails__location {
        padding: 5px;
    }
    .eventDetails__container .eventDetails__location p,
    .eventDetails__container .eventDetails__location a {
        font-size: .8rem;
    }
    .eventDetails__container .eventDetails__location .map-icon {
        font-size: 1.5rem;
    }
    .eventDetails__container .eventDetails__subtitle {
        font-size: 1rem;
    }
    .eventDetails__container .eventDetails__dates {
    font-size: 1.1rem;
    }
    .eventDetails__container a {
        margin-left: 4vw;
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .eventDetails__container {
        padding: 22px 9px;
        /* gap: 300px; */
    }
}

@media screen and (max-width: 380px) {
    .eventDetails__container {
        padding: 22px 9px;
        /* gap: 400px; */
    }
}