
.auditions__container .auditions__title {
    padding: 30px 4px;
}
.auditions__container .auditions__flex {
    display: flex;
    justify-content: space-around;
    width: 92vw;
    margin: 0 auto 30px;
}
.auditions__container .auditions__paragraph p {
    margin: 18px auto;
}
.auditions__container .auditions__paragraph {
    width: min(50ch, 92vw);
    margin: 12px auto;
    text-align: justify;
}
.auditions__container .auditions__paragraph a {
    font-size: inherit;
    font-weight: 600;
}
.auditions__container .auditions__paragraph a:hover {
    color: var(--blue);
}

.auditions__container img {
    width: min(500px, 92vw);
    height: min(500px, 92vw);
    object-fit: cover;
    display: block;
    margin: 18px auto;
}


@media screen and (max-width: 1100px) {
    .auditions__container .auditions__flex {
        flex-direction: column;
        width: 100%;
    }
}