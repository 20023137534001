.popup__container {
    position: absolute;
    top: 50vh;
    left: 35vw;
    transform: translate(-500%, -50%);

    width: min(330px, 80vw);
    height: min(330px, 80vw);
    border-radius: 50%;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid var(--blue);
    border: 2px solid #eee;
    background: linear-gradient(120deg, hsl(162, 100%, 40%), hsl(176, 100%, 35%), hsl(180, 100%, 30%));;
    background: linear-gradient(177deg, #e6fff7, #84fde5, #00FFFF);
    color: #222;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    z-index: 100;
    opacity: 1;
    transition: opacity 1500ms, transform 1500ms;
}
.slide-in {
    opacity: 1;
    transform: translate(0%, -50%);
}


/* .popup__container:hover .popup__close {
    display: block;
} */
.popup__container p {
    margin: 25% 0 0 9%;
    text-align: justify;
    width: 80%;
    /* border: 1px solid white; */
}

.popup__container button {
    display: block;
    margin: 10px auto 15%;
}

.popup__close {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    /* display: none; */
    right: 0px;
    top: 20px;
    transform: translateY(-50%);
    font-size: 2.8rem;
    border: 2px solid #eee;
    background: #f8f8f8b5;
    box-shadow: var(--clear);
}
.popup__close:hover {
    background-color: var(--pink);
    cursor: pointer;
}

@media screen and (max-width: 880px) {
    .popup__container {
        left: 15vw;
    }
}
@media screen and (max-width: 450px) {
    .popup__container {
        left: 10vw;
        top: 60vh;
    }
    .popup__container p {
        font-size: 1rem;
        margin: 0% 0 0 13%;
    }
    .popup__container button {
        display: block;
        margin: 15% auto -5%;
    }
}
@media screen and (max-width: 330px) {
    .popup__container {
        left: 5vw;
    }
    .popup__container p {
        font-size: .8rem;
    }
}