.board__container {
    /* background: var(--feature); */
    padding-bottom: 90px;
}
.board__container .board__title {
    margin-top: 50px;
    text-align: center;
    /* font-family: var(--noble); */
    /* font-weight: 400; */
    font-size: 2.9rem;
}

.board__flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.board__flex img {
    display: block;
    width: min(400px, 84vw);
    height: min(400px, 84vw);
    object-fit: cover;
    margin: 30px auto;
    rotate: 0deg;
    transition: rotate 500ms, box-shadow 500ms, border 500ms;
    border: 0px solid var(--blue);
}
.board__image-left:hover {
    rotate: -2deg;
    box-shadow: var(--spread);
    cursor: pointer;
    border: 15px solid var(--blue);
}
.board__image-right:hover {
    rotate: 2deg;
    box-shadow: var(--spread);
    cursor: pointer;
    border: 15px solid var(--blue);
}

.board__popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: #3333337f;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.board__popup-content {
    background: #f8f8f8;
    padding: 40px;
    position: relative;
    max-height: 100vh;
    width: min(700px, 92vw);
    overflow: auto;
}
.board__container .board__popup-content p {
    width: min(50ch, 100%);
    margin: 30px auto;
    text-align: justify;
}
.board__popup-content p a {
    font-size: inherit;
    font-weight: 600;
}
.board__popup-content p a:hover {
    color: var(--blue);
}
.board__popup-close {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 2px solid var(--red);
    color: var(--red);
    width: 45px;
    height: 45px;
    font-size: 3rem;
}
.board__popup-close:hover {
    cursor: pointer;
    background: rgb(249, 233, 233);
}

.board__container h2,
.board__container h3 {
    text-align: center;
    margin-bottom: 12px;
    /* font-family: var(--noble); */
    font-size: 2rem;
    color: var(--text);
}
.board__container h3 {
    font-size: 1.6rem;
}
.board_bod-title {
    margin-top: 110px;
}
.board__board {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    width: min(1000px, 90vw);
    margin: auto;
}
.board__board h3 {
    margin: 20px auto;
    /* font-family: var(--noble); */
    font-weight: 400;
    padding: 12px 20px;
    box-shadow: var(--sharp);
    background: var(--blue);
    color: #f8f8f8;
    /* border-radius: 10px; */
}

.board__flex p {
    margin-bottom: 40px;
    width: min(50ch, 90vw);
}

@media screen and (max-width: 1200px) {
    .board__container {
        padding-bottom: 90px;
    }
    .board__flex {
        flex-direction: column;
    }
    .board__flex p {
        margin: 10px auto 40px;
    }
}
@media screen and (max-width: 900px) {
    .board__board {
        margin: 40px auto;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        /* background-color: transparent; */
    }
}
@media screen and (max-width: 450px) {
    .board__popup-content {
        padding: 60px 12px;
    }
    .board__container {
        padding-bottom: 90px;
    }
    .board__container .board__board h3 {
        font-size: 2rem;
    }
    .board__board div {
        background: var(--blue);
        width: 95vw;
        border-radius: 10px;
        margin: 20px 0;
    }
}