.forums__container {
    padding-bottom: 90px;
}
.forums__item {
    margin-bottom: 60px;
}
.forums__item h2 {
    text-align: center;
    margin: 20px auto;
}
.forums__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1080px;
    margin: auto;
}
.forums__content {
    padding: 0 10px;
    width: min(500px, 90vw);
}
.forums__grid img {
    width: min(500px, 90vw);
    height: min(500px, 90vw);
    object-fit: cover;
}

@media screen and (max-width: 1080px) {
    .forums__grid {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 50px;
        margin: auto;
        width: 90%;
    }
    .forums__content {
        order: 2;
        display: block;
        margin: auto;
    }
    .forums__grid img {
        order: 1;
        display: block;
        margin: auto;
    }
}