.escuela__googleBTN {
    display: block;
    margin: 80px auto 80px;
}
.escuela__container h2 {
    display: block;
    margin: 40px auto;
    text-align: center;
}

.escuela__description2 {
    width: min(75ch, 92vw);
    margin: 40px auto 10px;
    text-align: justify;
}
.escuela__description2 a {
    font-size: inherit;
    font-weight: 600;
}
.escuela__description2 a:hover {
    color: var(--blue);
    cursor: pointer;
}


.escuela__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1000px;
    margin: 30px auto;
    gap: 50px;
}

.escuela__grid p {
    width: min(50ch, 90vw);
    margin-top: 12px;
}
.escuela__grid p span,
.escuela__registration {
    font-weight: 600;
}
.escuela__grid p a {
    font-size: inherit;
    font-weight: 600;
}
.escuela__grid p a:hover {
    color: var(--blue);
    cursor: pointer;
}
.escuela__grid .escuela__map-icon {
    font-size: 1.4rem;
    margin-bottom: -4px;
}

.escuela__grid img {
    width: min(500px, 100%);
}

.escuela__googleForm {
    margin: 50px auto;
    display: block;
}
.escuela__googleForm iframe {
    width: min(600px, 98vw);
    height: 800px;
    margin: auto;
    display: block;
    border: none;
}


/* ---- Swiper ---- */

.mySwiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 350px;
    margin: 50px 0;
  }
  
.escuela__container .swiper-slide {
    width: 300px;
    height: 300px;
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    object-fit: cover;
    padding: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #f8f8f8;
  }
  .mySwiper .swiper-pagination-bullet-active {
    background-color: var(--aqua);
    border: 1px solid #6464646b;
  }



@media screen and (max-width: 1050px) {
    .escuela__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        width: min(500px, 92vw);
        margin: auto;
        gap: 50px;
    }
    .escuela__grid p,
    .escuela__grid img {
        margin: 12px auto;
    }
}