.bts__container {
    padding-bottom: 40px;
}

.bts__container h2,
.bts__container h3 {
    margin-top: 50px;
    text-align: center;
}

.bts__container p {
    width: min(50ch, 90%);
    margin: auto;
}