.donate__container {
    margin: 40px auto;
}
.donate__container p,
.donate__container button
{
    display: block;
    margin: 20px auto;
}
.donate__container p {
    text-align: center;
    padding: 0 20px;
}

@media screen and (max-width: 520px) {
    .donate__container p {
        text-align: left;
    }
    .donate__container button {
    display: block;
    margin: 20px;
}
}