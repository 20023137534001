.volunteer__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 1200px;
    margin: 80px auto;
}
.volunteer__grid .v1-computer {
    width: 500px;
    height: 500px;
    object-fit: cover;
    object-position: -100px;
    margin: 50px auto 0;
}
.volunteer__grid .v1-phone {
    display: none;
}

.volunteer__content p {
    width: min(50ch, 100%);
    margin: 18px auto;
    text-align: justify;
}
.volunteer__content button {
    display: block;
    margin: 40px auto;
}

.volunteer__form {
    margin: 50px auto;
    display: block;
}
.volunteer__form iframe {
    width: min(600px, 98vw);
    height: 800px;
    margin: auto;
    display: block;
    border: none;
}


@media screen and (max-width: 1280px) {
    .volunteer__container h2 {
        text-align: center;
        margin: 40px 0;
        padding: 0;
    }
    .volunteer__grid {
        display: block;
        width: 92vw;
        margin: auto;
    }
    .volunteer__grid .v1-phone {
        display: block;
        margin: 40px auto;
        width: min(500px, 92vw);
        height: min(500px, 92vw);
        object-fit: cover;
        object-position: -100px;
    }
    .volunteer__grid .v1-computer {
        display: none;
    }
}