.contact {
    min-height: 100vh;
    width: 100%;
    /* background: var(--blue); */
    background-image: url('../../img/backgroundOptimized.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 40px;
    z-index: 0;
}
.contact__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    z-index: 10;
    min-height: 100vh;


}

/* The actual box */
.contact__box {
    position: relative;
    width: 480px;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    background: hsla(0, 0%, 95%, 0.92);
    backdrop-filter: blur(4px);
}


.box__border {
    position: absolute;
    top: 0;
    inset: 0;
}


.contact__box form 
{
    position: absolute;
    inset: 4px;
    background: #e5e5e51a;
    padding: 20px 20px;
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.contact__box .contact__title 
{
    color: #222 !important;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.1em;
}

.contact__box form .box__input 
{
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.contact__box form .box__input input 
{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #f8f8f8;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
}

.contact__box form .box__input span
{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    color: #444;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.contact__box form .box__input input:valid ~ span,
.contact__box form .box__input input:focus ~ span
{
    color: #444;
    font-size: 0.75em;
    transform: translateY(-34px);
}

.contact__box form .box__input i
{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #444;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.contact__box form .box__input input:valid ~ i,
.contact__box form .box__input input:focus ~ i
{
    height: 44px;
}


/* .contact__box form input[type="submit"]
{
    border: none;
    padding: 9px 25px;
    background: #ffffffc4;
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 4px;
    font-weight: 600;
    width: 100px;
    margin-top: 10px;
} */

/* .contact__box form input[type="submit"]:active,
.contact__box form input[type="submit"]:hover
{
    opacity: 0.8;
    background: radial-gradient(circle at 20px 60px, rgb(52, 167, 199), rgb(36, 251, 251), rgb(136, 251, 251),  rgb(95, 250, 198), rgb(122, 249, 162), rgb(41, 255, 102) );
} */


.contact__box form .box__input textarea
{
    position: relative;
    width: 100%;
    height: 150px;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #f8f8f8;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
    resize: none;
}



.contact__box form .box__input textarea:valid ~ span,
.contact__box form .box__input textarea:focus ~ span
{
    color: #333;
    font-size: 0.75em;
    transform: translateY(-34px);
}


.contact__box form .box__input textarea:valid ~ i,
.contact__box form .box__input textarea:focus ~ i
{
    height: 150px;
}

.contact__box button {
    margin: 40px auto;
    background: #f8f8f8;
    border-color: hsl(171, 54%, 41%);
    color: hsl(171, 54%, 41%);
    transition: 1s;
    box-shadow: var(--spread);
}
.contact__box button:hover {
    border-color: #222;
    color: #222;
    background: linear-gradient(to bottom, #89E0B6, hsl(151, 48%, 61%));
}

.contact__send-email {
    height: max-content;
    display: block;
    width: max-content;
    margin: 25px auto 5px;
    border: 3px solid var(--text);
    color: var(--text);
    font-weight: 600;
    padding: 10px 20px;
    font-size: 26px;
    background: #f8f8f8;
    white-space: nowrap;
    transition: 1s;
    font-family: var(--clear);
}
.contact__send-email:hover {
    background: linear-gradient(to bottom, #89E0B6, hsl(151, 48%, 61%));
    cursor: pointer;
  }
  