#banner__container {
    width: 100%;
    background-color: #111;
    background-image: url('../../img/banner2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #f8f8f8;
    position: relative;
    z-index: 0;
}

#banner__container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, 0.62);
    z-index: 1;
}

#banner__container p {
    padding: 70px 10px;
    /* font-family: var(--fun); */
    font-family: 'CulturaLight';
    font-weight: 300;
    position: relative;
    text-align: center;
    font-size: 2.4rem;
    z-index: 1;
    color: var(--aqua);
    background-image: linear-gradient(177deg, #e6fff7, #84fde5, #00FFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
}

#banner__container p strong {
    /* font-weight: 700; */
    font-family: 'CulturaMedium';
    font-weight: 300;
}