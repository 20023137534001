.mission__container {
    padding: 0px 0px 40px;
}

.mission__flex {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin: auto;
}

.mission__flex img {
    width: min(500px, 92vw);
    height: min(500px, 92vw);
    margin: 20px auto;
    object-fit: cover;

}

.mission__flex .mission__content {
    margin: 20px auto;
    padding: 0px 20px;
}

.mission__content h2 {
    margin-bottom: 20px;
    font-size: 3rem;
}

.mission__content p {
    width: min(50ch, 90vw);
    text-align: justify;
}
.mission__content p a {
    font-size: inherit;
    font-weight: 600;
}
.mission__content p a:hover {
    color: var(--blue);
}

@media screen and (max-width: 1130px) {
    .mission__flex {
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {
    .mission__flex img {
        margin: 20px auto;
    }
    .mission__flex .mission__content {
        padding: 4px;
        margin: auto;
    }
}