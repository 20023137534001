.gallery__container {
    padding-bottom: 40px;
}

.gallery__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 40px;
}

.gallery__container img,
.gallery__container video {
    width: 300px;
    height: 400px;
    object-fit: cover;
    margin: 10px;
}
.gallery__container video {
    box-shadow: var(--spread);
}
.gallery__container p {
    text-align: center;
    margin-bottom: 20px;
    width: min(300px, 90%);
}

.gallery__container .gallery__adjust-right {
    object-position: -20px;
}
.gallery__container .gallery__adjust-rightHard {
    object-position: -70px;
    object-position: 90%;
}

.gallery__container .gallery__adjust-left {
    object-position: -270px;
}
.gallery__container .gallery__adjust-left2 {
    object-position: -210px;
    object-position: 68%;
}

.gallery__more-content {
    width: 250px;
    margin: 30px auto;
    background: var(--red);
    border: 1px solid #222;
    color: #f8f8f8;
    padding: 20px
}
.gallery__more-content-flex {
    display: flex;
    flex-direction: column;
}
.gallery__more-content-flex a {
    text-align: center;
}
.gallery__more-content-flex .gallery__icon {
    margin-bottom: -3px;
}
.gallery__more-content-flex a:hover {
    color: var(--aqua);
}
@media screen and (max-width: 730px) {
    .gallery__flex {
        flex-direction: column;
        align-items: center;
    }
    .gallery__container img,
    .gallery__container video {
        width: min(300px, 94vw);
        height: min(400px, 110vw);
    }
}